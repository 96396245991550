<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-profile
          :module_name="page.name"
          :record="record"
          @change="save()"
          @archive="archive()"
          :user_type="page.model">
          <template v-slot:below_photo>
            <v-row>
              <v-col v-if="record.person && !record.person.user" class="text-center">
                <base-create-user
                    modal_name="base_create_user"
                    :person_id="record.person.id"
                    :user_type="page.model"
                    :emailaddress="getEmailaddress"
                    :person="record.person"
                    @refresh="load()"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="record.person && record.person.user" class="text-center">
                <base-notification
                    modal_name="base_notification"
                    @refresh="load()"
                    :user_id="record.person.user.id"
                    :recipient="record.person.name"
                    :notifications="record.person.user.notifications"
                    :phonenumber="record.person.mobilenumber"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="record.person && record.person.user" class="text-center">
                <v-btn @click="resetPassword()" :loading="reset_password_loading" small>
                  {{ $store.getters.translate("reset_password") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="record.person && record.person.user" class="text-center">
                <v-btn @click="deleteUser()" :loading="reset_delete_user_loading" small color="error">
                  {{ $store.getters.translate("delete_account") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </base-profile>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="companies_table" :page="page" :parent_module_id="record.id" relation_name="companies" @edit="editCompany" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <edit-note modal_name="edit_note" :module_name="page.model" :model_id="record.id" ref="edit_note" @refresh="load()"/>
    <create-note modal_name="create_note" :module_name="page.name" :model_id="record.id" ref="create_note" @refresh="load()"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseProfile from "../../components/commonComponents/BaseProfile";
import EditNote from "../notes/Edit";
import CreateNote from "../notes/Create";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseBreadcrumb,
    BaseTableIncludable,
    BaseProfile,
    EditNote,
    CreateNote,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("contacts"),
        name: "contacts",
        model: "contact",
      },
      record: {
        person: {}
      },
      loading: false,
      reset_password_loading: false,
      reset_delete_user_loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.updateIncludableTables();
      this.loading = false;
    },
    updateIncludableTables() {
      this.$refs.companies_table.records = this.record.companies;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.companies_table.selected = [];
      this.$refs.notes_table.selected = [];
    },
    async save() {
      this.loading = true;
      if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
        await this.load();
      }
      this.loading = false;
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    resetPassword() {
      this.reset_password_loading = true;
      if (this.record.person && this.record.person.user) {
        this.$http
            .post(this.$store.getters.appUrl + "v2/resetpassword", { email: this.record.person.user.email })
            .then(() => {
              this.reset_password_loading = false;
              this.$toasted.success(this.$store.getters.translate("password_reset_requested"));
              this.$emit("refresh");
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
      else {
        this.$toasted.error(this.$store.getters.translate("no_user"));
      }
    },
    deleteUser() {
      this.reset_delete_user_loading = true;
      this.record.person.delete_user = true;
      this.$http
          .patch(this.$store.getters.appUrl + "v2/people/" + this.record.person.id, this.record.person)
          .then(() => {
            this.reset_delete_user_loading = false;
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    editNote(item) {
      this.$refs.edit_note.load(item.id);
    },
    editCompany(item) {
      this.$router.push("/companies/" + item.id);
    },
    createNote() {
      this.$refs.create_note.load();
    },
  },
  computed: {
    getEmailaddress() {
      if (this.record.person.emailaddresses.length > 0) {
        return this.record.person.emailaddresses[0].emailaddress;
      }
      return null;
    },
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>
